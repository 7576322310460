import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import {  Header } from 'semantic-ui-react';

import logoSP from "../../images/SPlogo3.png";


import nttdata from '../../images/ntt.jpg';
import birla from '../../images/birla4.jpg';
import kforce from '../../images/kforce.jpg';
import experis from '../../images/experis.jpg';
import judje from '../../images/judje.jpg';
import randstad from '../../images/randstad.jpg';
import tek from '../../images/tek.jpg';
import team from '../../images/team.png';
import waves from '../../images/waves.gif';

import bgImage1 from "../../images/image01.jpg";
import './Home.css';
import EastIcon from '@mui/icons-material/East';
import { useMediaQuery } from 'react-responsive'

import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Pagination,
  Autoplay,
} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import { useEffect, useState } from 'react';


export const MottoSection = () => {
  const navigate = useNavigate();
  
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className='hero' >
      <div className='hero__flex'  >
        <div className='hero__right' >
        <div className='hero__rightbox'>
            <Header  className='hero_heading' >
         SP TECHNO 
           <br  />SOLUTIONS INC.  </Header >
            <div className='rightbox_paragraph'>
              We are smart, creative in transforming your Business
            </div>
        </div>
        </div>
        <div className='hero__left' style={{ backgroundImage: `url(${bgImage1})`, backgroundSize: 'cover', background: 'cover' }} >
         
       </div>
      
      </div>
    </section>
  )
}
export const FeaturesSection = () => {

  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo(0,700);
   }
   const scrollToTop2 = () => {
    window.scrollTo(0,1500);
   }
   const scrollToTop3 = () => {
    window.scrollTo(0,2200);
   }
   const scrollToTop4 = () => {
    window.scrollTo(0,3000);
   }
  return (
    <section className="feature">
      <h1 className="feature__heading">SP TECHNO</h1>
      <div className="feature__heading2">SOLUTIONS INC.</div>
      <div className="feature__line"></div>
      <div className="feature__box" >
      <div className="feature__heading3">Services <br></br>We Provide</div>
      <div className='para'>
      SP Techno Solutions offers a wide range of staffing services, including contract staffing, contract-to-hire, and direct placement. 
      They cover various IT domains such as software development, data analytics, cybersecurity, cloud computing, and enterprise resource planning (ERP) systems. 
      Their pool of talent includes experts in emerging technologies like artificial intelligence, machine learning, and DevOps.
          <br></br><br></br>

          </div>
      </div>
     
      <div className='feature__content'>
        <div className="feature__flex">
        <div className="feature__item"
         style={{backgroundColor: '#CBD2A4'}}
            onClick={() => {
              navigate("/product");
                scrollToTop();
            }}>
            <div className="feature__item-heading">
              <img
                src={logoSP}
                alt="Class"
                className="feature__item-image"
              />
              
              <h3><strong className="bold-text-2">Support & Staffing<br></br>Solutions</strong></h3>
            </div>
            
          </div>
          <div className="feature__item"
           style={{backgroundColor: '#E9EED9'}}
            onClick={() => {
              navigate("/product");
                scrollToTop2();
            }}>
            <div className="feature__item-heading">
              <img
                src={logoSP}
                alt="Class"
                className="feature__item-image"
              />
              
              <h3><strong className="bold-text-2">Software <br></br>Development</strong></h3>
            </div>
         
          </div>
          <div className="feature__item"
           style={{backgroundColor: '#CBD2B4'}}
            onClick={() => {
              navigate("/product");
                scrollToTop3();
            }}>
            <div className="feature__item-heading">
              <img
                src={logoSP}
                alt="Under Voltage
                Protection
                "
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">IT Consulting<br></br>Sevices</strong></h3>
            </div>
            
            {/* <div className="feature__item-desc">
              <p>
                Protects the battery pack from over-discharging by terminating
                the discharging process, when pack is completely drained
              </p>
            </div> */}
          </div>
          <div className="feature__item"
           style={{backgroundColor: '#d9e39f'}}
            onClick={() => {
              navigate("/product");
                scrollToTop4();
            }}>
            <div className="feature__item-heading">
              <img
                src={logoSP}
                alt="Temperature
                Protection"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Mobile App<br></br>Development</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Managing classes and subjects<br></br>
                Create/Manage classes and sections<br></br>
                Add/Manage subjects<br></br>
              </p>

              <KeyboardDoubleArrowRightOutlinedIcon className='icon'></KeyboardDoubleArrowRightOutlinedIcon>

            </div>
            {/* <div className="feature__item-desc">
              <p>
                Over temperature and under temperature protection while
                charging or discharging
              </p>
            </div> */}
          </div>
         
        </div>
       
      </div>
    </section>
  );
}
export const DescriptionSection2 = () => {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 900px)' })

  const scrollToTop = () => {
    window.scrollTo(0, 0);
}
const params = {
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
};

  return (
    <section className="second-section2" >
      <div className="second-section__rightui">
        <div>
          <h1 className='title'>A Proven Track Record</h1>
          {/* <p className='description1'> At OptimumP, we offer good quality 
        <strong>Battery Management System (BMS)</strong>.
         The Battery Management System (BMS) we offer are manufactured under strict quality 
         controls and all the BMS go through high standard Quality Contol before reaching to Indian Market.</p> */}
          <p>
          SPTechno Has Worked Exclusively With Established 
Global Brands .
          </p>
        </div>
        {/* <div className='description-button'>
          <Button primary><a href='/product' style={{color: "white"}}>Learn More</a></Button>
        </div> */}
        <div style={{height:200,width:'100%',marginTop:50}}>
            <Swiper
        className='swiper'
        slidesPerView={isMobileScreen ? 2 : 5}
     
          loop={true}
          centeredSlides={true}
          spaceBetween={0}
          
          grabCursor={true}
          pagination={{
            clickable: true,
          }}
          speed={2000}
          modules={[Autoplay,Pagination]}
          autoplay={{
            reverseDirection:true
          }}
        >
          <SwiperSlide className="slide1" >
            <img  className="img3" src={nttdata}  />
          </SwiperSlide>
          <SwiperSlide className="slide1" >
            <img  className="img3" src={birla}  />
          </SwiperSlide>
          <SwiperSlide className="slide1"  >
            <img  className="img3" src={kforce}  />
          </SwiperSlide>
          <SwiperSlide className="slide1"  >
            <img  className="img3" src={experis}  />
          </SwiperSlide>
         
          <SwiperSlide className="slide1"  >
            <img  className="img3" src={randstad}  />
          </SwiperSlide>
          <SwiperSlide className="slide1"  >
            <img  className="img3" src={judje}  />
          </SwiperSlide>
          <SwiperSlide className="slide1"  >
            <img  className="img3" src={tek}  />
          </SwiperSlide>

          
        </Swiper>
</div>

     
      
       
      </div>
      {/* <div className="second-section__left">
        <img src={logo} alt='Qshikshak' className='icon' />
      </div> */}


    </section>
  );
}
export const DescriptionSection3 = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
      window.scrollTo(0,0);
     }
  return (
    <section className="third-section" >


    
      <div className="third-section__right2">
      <div className='des_image'>
          <div className='des_imagein'>
        <img src={logoSP} alt='Qshikshak' className='icon'  />
        </div>
        </div>
        <div className="content" >
          <h1 className='title'>Proven 
          <br></br>Expertise</h1>

          <div className='para'>
          <img src={logoSP} alt='Qshikshak' className='icon2'  />
          We believe in going above and beyond the conventional standards to provide our clients with exemplary results, second to none.
          <br></br><br></br>

          </div>
          <div className='button1' onClick={() => {
                navigate("/about-us");
                scrollToTop();
              }}> 
          Why Choose SP Techno
          <EastIcon className='arrow' ></EastIcon>
          </div>
        </div>
       
        
      </div>
     

    </section>
  );
}
export const DescriptionSection = () => {
  return (
    <section className="second-section" >


      
      <div className="second-section__right2" style={{ backgroundImage: `url(${team})`, backgroundSize: 'cover', background: 'cover' }}>

        <div className="content" >
          <h1 className='title'>Our Team
          <br></br></h1>

          <div className='para'>
          We are a team of seasoned professionals passionate about 
          empowering businesses through strategic staffing solutions. Our team comprises 
          industry experts in IT staffing, recruitment, operations, and immigration management, 
          with a combined experience spanning decades. Our leadership brings over 11 years
           of expertise in US IT Bench Sales, Operations, Accounts, and comprehensive P&L management.
          <br></br><br></br>
          </div>
        </div>
       
        
      </div>
  

    </section>
  );
}
export const DescriptionSection4 = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo(0,0);
   }
  return (
    <section className="contact-section" >


      
      <div className="contact-section__right2" style={{ backgroundImage: `url(${waves})`, backgroundSize: 'cover', background: 'cover' }}>

        <div className="content" style={{ borderRight : '3px solid white',marginRight:10,paddingRight:10}}>
          <h1 className='title'>Get In Touch
          <br></br></h1>

          <div className='button1'   
          onClick={() => {
              navigate("/contact");
                scrollToTop();
            }}> 
          Contact Us
         
          </div>
        </div>
        <div className="content"  >
          <h1 className='title'>Looking For Job?
          <br></br></h1>
          <div className='button1'  onClick={() => {
              navigate("/apply");
                scrollToTop();
            }}> 
          Apply Now
         
          </div>
        
        </div>
        
      </div>
  

    </section>
  );
}




export const Home = () => {
  return (
    <>
      <MottoSection />
      <FeaturesSection />
      <DescriptionSection2 />
      <DescriptionSection3 />
      <DescriptionSection />
      <DescriptionSection4 />
      
      
    </>
  )
}
