import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";
import { Header, TabNames } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Contact } from "./components/Contact/Contact";
import { Home } from "./components/Home/Home";
import { Product } from "./components/Product/Product";
import { Apply } from "./components/Apply/Apply"
import { About } from "./components/About/About";
import { useEffect, useState } from "react";
import { MenuItemProps } from "semantic-ui-react";
// import { Mobile } from "./components/MobileF/Mobile";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import "./App.css";

export const App = () => {
  const [activeItem, setActiveItem] = useState<TabNames>(TabNames.HOME);
  const location = useLocation()

  const [navBg, setNavBg] = useState(true);


  const changeNavBg = () => {
      window.scrollY >= 150 ? setNavBg(true) : setNavBg(false);
     }
   
     useEffect(() => {
       window.addEventListener('scroll', changeNavBg);
       return () => {
         window.removeEventListener('scroll', changeNavBg);
       }
     }, []);
     const scrollToTop = () => {
      window.scrollTo(0,0);
     }

  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const pathname1 = location.pathname.split("?")[0];  
    console.log(pathname1,'ssssss');
          
    switch(pathname1) {
        case "/product":
            setActiveItem(TabNames.PRODUCTS);
            break;
            case "/apply":
              setActiveItem(TabNames.MOBILE);
              break;
        case "/contact":
            setActiveItem(TabNames.CONTACT);
            break;
            case "/demo":
            setActiveItem(TabNames.DEMO);
            break;
        case "/about-us":
            setActiveItem(TabNames.ABOUT_US);
            break;
        default:
            setActiveItem(TabNames.HOME);
            break;
    }        
  }, [location.pathname])

  const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
    if (data.name) {
        setActiveItem(data.name as TabNames);
        console.log(data.name,TabNames.PRODUCTS,"namesssss");
        
        if (data.name === TabNames.PRODUCTS) 
        {
          setSearchParams({q:'Class_Student'})
        }
        
    }
  }

  return (
    <>
      <Header activeItem={activeItem} handleItemClick={handleItemClick} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
        </Routes>
      <Footer activeItem={activeItem} handleItemClick={handleItemClick}/>
   
      <ArrowUpwardOutlinedIcon className= {navBg ? ('Uparrow') : ('UparrowHiden')} onClick={scrollToTop}></ArrowUpwardOutlinedIcon>
    </>
  );
}