import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, MenuItemProps } from 'semantic-ui-react'
import logo from '../../images/SPlogo2.png'
import './Header.css'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import MenuIcon from '@mui/icons-material/Menu';

export enum TabNames {
    HOME = 'Home',
    PRODUCTS = "Services",
    MOBILE = "Apply Job",
    CONTACT = "Contact Us",
    DEMO = "Demo",
    ABOUT_US = "About Us"
}

export const Header = (props: {
    activeItem: TabNames,
    handleItemClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => void
}) => {
    const [open, setOpen] = useState<boolean>(false);

    const [navBg, setNavBg] = useState(false);


    // const changeNavBg = () => {
    //     window.scrollY >= 150 ? setNavBg(true) : setNavBg(false);
    // }

    // useEffect(() => {
    //     window.addEventListener('scroll', changeNavBg);
    //     return () => {
    //         window.removeEventListener('scroll', changeNavBg);
    //     }
    // }, [])

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <div className='top-bar'>
            <div className={ 'top-bar_right' }  >
            <div  className='footer-menu-item2' style={{ borderRight : '1px solid black',marginRight:10,paddingRight:10}}>
                   <EmailOutlinedIcon className='icon1'></EmailOutlinedIcon>
                  
                     <div >
                
                  <div >Info@sptechnosolutions.com </div>
                  </div>
                </div>
            <div  className='footer-menu-item2'>
            <LocalPhoneOutlinedIcon  className='icon1'></LocalPhoneOutlinedIcon> 
                  
                     <div >
                
                  <div  >1937234862 </div>
                  </div>
                </div>
               
            </div>
            <a className='logo-link' href='/'>
                <img src={logo} alt='SPtechno' className='logo' />
                {/* <div className='footer__pvt'>SPtechno</div> */}
            </a>
            <div className='mobile-bar' onClick={() => {
                setOpen(!open)
            }}>
                
                <MenuIcon  className='iconMenu'></MenuIcon> 
                {/* <Icon name='bars' size='huge' /> */}
            </div>

            <Menu fixed='top' className={!navBg ? ('header-layout') : ('header-layout active')} stackable>
                <Menu.Menu
                    position='left'
                    className={open ? 'nav-bar-open' : 'nav-bar-hide'}
                >
                    {/* <Menu.Item
                        name={TabNames.HOME}
                        active={props.activeItem === TabNames.HOME}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            // scrollToTop();
                            scrollToTop();
                        }}
                        as={Link}
                        to='/'
                    // className='tab-style'
                    // color=''
                    /> */}
                    <Menu.Item
                        name={TabNames.PRODUCTS}
                        active={props.activeItem === TabNames.PRODUCTS}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/product'
                    // color='red'
                    />
                    <Menu.Item
                        name={TabNames.MOBILE}
                        active={props.activeItem === TabNames.MOBILE}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/apply'
                    // color='red'
                    />
                    {/* <Menu.Item
                        name={TabNames.MOBILE}
                        active={props.activeItem === TabNames.MOBILE}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/mobile'
                    /> */}
                    <Menu.Item
                        name={TabNames.CONTACT}
                        active={props.activeItem === TabNames.CONTACT}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/contact'
                    />
                    <Menu.Item
                        name={TabNames.ABOUT_US}
                        active={props.activeItem === TabNames.ABOUT_US}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                        }}
                        as={Link}
                        to='/about-us'
                    />
                

                </Menu.Menu>
            </Menu>

        </div>
    )
}