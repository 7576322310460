import { Header } from 'semantic-ui-react'
import './Product.css'
// import bgGround from '../../images/micro-controller-bg.png'

import bgImage1 from "../../images/image01.jpg";
import staff from "../../images/staffing02.jpg";
import software from "../../images/software02.jpg"
import mobile from "../../images/mobileapp.jpeg"
import logoSP from "../../images/SPlogo3.png";
import waves from '../../images/waves.gif';
import { useNavigate } from 'react-router-dom'; 
import { useMediaQuery } from 'react-responsive'

export const Product = () => {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 900px)' })
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo(0,0);
   }
  return (
    <div className='product' >
     <section className='hero' >
      <div className='hero__flex'  >
       
        <div className='hero__right' >
        <div className='hero__rightbox2'>
            
        
     <Header  className='hero_heading2'   >
        Services
         
         </Header >
        </div>
        </div>
        <div className='hero__left' style={{ backgroundImage: `url(${bgImage1})`, backgroundSize: 'cover', background: 'cover' }} >
         
       </div>
        {/* <div className='hero__right' style={{ backgroundImage: `url(${bgGround})` }}>
        </div> */}
      </div>
    </section>
    <section className="third-section22"  >
<div className="third-section__right22" >
<div className='des_image21' style={{ backgroundImage: `url(${staff})`, backgroundSize: 'cover', background: 'cover' }}>
    <div className='des_imagein21'>
  <img src={logoSP} alt='Qshikshak' className='icon21'  />
  </div>
  </div>
  <div className="content" >
    <h1 className='title'>Support &
 
    <br></br>Staffing Services</h1>

    <div className='para'>
    <img src={logoSP} alt='Qshikshak' className='icon2'  />
    SP Techno Solutions Inc. is a fully blended company 
    with national recruiting specialists in office, 
    administrative, engineering, healthcare, marketing, 
    accounting, finance, information technology, and skilled 
    labor. SP Techno Solutions Inc. has the experience and 
    expertise in national job placement development to fulfill 
    your company’s staffing needs.    <br></br><br></br>

    </div>
    <ul>
              <li>Job Requisition</li>
              <li>Immigration Services</li>
              <li>
              Employer Verification
              </li>
              <li>
              Background Checks
              </li>
              <li>
              Administrative
              </li>
              <li>
              Candidate Screening
              </li>
            </ul>
  </div>
</div>
</section>
<section className="third-section22"  >
<div className="third-section__right22"  style={{display:'flex',flexDirection:(isMobileScreen ? 'column':'row-reverse') ,}}>
<div className='des_image21' style={{ backgroundImage: `url(${software})`, backgroundSize: 'cover', background: 'cover' }}>
    <div className='des_imagein21'>
  <img src={logoSP} alt='Qshikshak' className='icon21'  />
  </div>
  </div>
  <div className="content" >
    <h1 className='title'>Software
 
    <br></br>Development</h1>

    <div className='para'>
    <img src={logoSP} alt='Qshikshak' className='icon2'  />
    Software development refers to a set of computer science 
    activities that are dedicated to the process of creating, 
    designing, deploying, and supporting software.Application 
    software (applications or apps) to help users perform tasks. 
    Office productivity suites, data management software, 
    media players and security programs are examples.     <br></br><br></br>

    </div>
    <ul>
              <li>Custom Development Solutions</li>
              <li>Application Maintenance Services</li>
              <li>
              Product Development
              </li>
              <li>
              MVP/ POC Development
              </li>
              
            </ul>
  </div>
 
  
</div>


</section>
<section className="third-section22"  >
<div className="third-section__right22" >
<div className='des_image21' style={{ backgroundImage: `url(${bgImage1})`, backgroundSize: 'cover', background: 'cover' }}>
    <div className='des_imagein21'>
  <img src={logoSP} alt='Qshikshak' className='icon21'  />
  </div>
  </div>
  <div className="content" >
    <h1 className='title'>IT Consulting
 
    <br></br>Services</h1>

    <div className='para'>
    <img src={logoSP} alt='Qshikshak' className='icon2'  />
    SP Techno Solutions Inc. offers a wide array of services, 
    including web and mobile application development, blockchain 
    solutions, UI/UX design, and SEO optimisation. Reviews highlight 
    the company’s strong project management, technical expertise, 
    and excellent customer support. Clients consistently praised 
    SP Techno Solutions Inc. for their flexibility, alignment with company values, 
    and commitment to delivering high-quality, timely solutions.    <br></br><br></br>

    </div>
    <ul>
              <li>Digital Transformation</li>
              <li>DevOps Implementation</li>
              <li>
              Software Product Development
              </li>
              <li>
              IT Infrastructure Upgrade
              </li>
             
            </ul>
  </div>
</div>
</section>
<section className="third-section22"  >
<div className="third-section__right22"  style={{display:'flex',flexDirection:(isMobileScreen ? 'column':'row-reverse')}}>
<div className='des_image21' style={{ backgroundImage: `url(${mobile})`, backgroundSize: 'cover', background: 'cover' }}>
    <div className='des_imagein21'>
  <img src={logoSP} alt='Qshikshak' className='icon21'  />
  </div>
  </div>
  <div className="content" >
    <h1 className='title'>Mobile App
 
    <br></br>Development</h1>

    <div className='para'>
    <img src={logoSP} alt='Qshikshak' className='icon2'  />
    We create mobile applications that work seamlessly across 
    every mobile platform, Android, Ios, and Windows. Whatever 
    be the niche your mobile project falls in, our mobile team 
    holds expertise in developing mobile apps for every industry.
    Our Resources holds the best mobile application developers and 
    technologies with a rare combination of rich industry experience 
    and unparalleled expertise to design and develop native Android, 
    iOS, and Cross-platform mobile apps.    <br></br><br></br>

    </div>
    <ul>
              <li>Android Apps Development</li>
              <li>IOS Apps Development</li>
              <li>
              Cross Platform Apps
              </li>
              <li>
              App Testing
              </li>
              
            </ul>
  </div>
 
  
</div>


</section>
<section className="contact-section" >


      
<div className="contact-section__right2" style={{ backgroundImage: `url(${waves})`, backgroundSize: 'cover', background: 'cover' }}>

  <div className="content" style={{ borderRight : '3px solid white',marginRight:10,paddingRight:10}}>
    <h1 className='title'>Get In Touch
    <br></br></h1>

    <div className='button1'  onClick={() => {
              navigate("/contact");
                scrollToTop();
            }}> 
    Contact Us
   
    </div>
  </div>
  <div className="content"   onClick={() => {
              navigate("/contact");
                scrollToTop();
            }}>
    <h1 className='title'>Looking For Job?
    <br></br></h1>
    <div className='button1'> 
    Apply Now
   
    </div>
  
  </div>
  
</div>


</section>
    </div>
  )
}