
import { Header } from "semantic-ui-react";

import "./About.css";


import bgImage1 from "../../images/image01.jpg";
import logoSP from "../../images/SPlogo3.png";
import waves from '../../images/waves.gif';
import MP from '../../images/MkP01.jpeg';
import WSP from '../../images/wSp.jpeg';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

export const About = () => {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 900px)' })
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo(0,0);
   }
  return (
    <>
      <div className="about">
      <section className='hero' >
      <div className='hero__flex'  >
       
        <div className='hero__right' >
        <div className='hero__rightbox2'>
            
        
     <Header  className='hero_heading2'   >
        About Us
         
         </Header >
        </div>
        </div>
        <div className='hero__left' style={{ backgroundImage: `url(${bgImage1})`, backgroundSize: 'cover', background: 'cover' }} >
         
       </div>
        {/* <div className='hero__right' style={{ backgroundImage: `url(${bgGround})` }}>
        </div> */}
      </div>
    </section>

    <section className="third-section" >


    
<div className="third-section__right2">
<div className='des_image'>
    <div className='des_imagein'>
  <img src={logoSP} alt='Qshikshak' className='icon'  />
  </div>
  </div>
  <div className="content" >
    <h1 className='title'>SP TECHNO
 
    <br></br>SOLUTIONS INC.</h1>

    <div className='para'>
    <img src={logoSP} alt='Qshikshak' className='icon2'  />
    SP Techno Solutions is a global technology services company 
    that helps enterprises to redesign their business to the 
    next digital generation. We are headquartered in the USA 
    with operations in India. Our core service offerings are 
    Cloud solutions, Data Science and Analytics, 
    Custom software development and IT staffing services.    <br></br><br></br>

    </div>
   
  </div>
 
  
</div>


</section>



<div className="about-content">
 <div className="second-section__right3">

<div className="content2" >
  <div style={{display:'flex',flexDirection:"row",justifyContent:'space-between',marginRight:30}}>
  <h1 className='title'>Why SP Techno 
  <br></br>Solutions?</h1>
  <img src={logoSP} alt='Qshikshak' style={{width:(isMobileScreen?90:160)}}  />
  </div>
  
<div style={{width:'100%',height:2, backgroundColor:'black',marginTop:30,marginBottom:30}}></div>
  <div className='para' style={{lineHeight:(isMobileScreen? 1.5:2)}}>
  <ul>
              <li>Builds trust and accountability.</li>
              <li>Shows unity through a shared vision.</li>
              <li>
                Helps us communicate externally who we are as a company and
                as a team.
              </li>
              <li>
                Gives us a framework to ground our decision making when
                conversations get tough.
              </li>
              <li>
                Flattens the organization. Guided by these values, critical
                and complimentary feedback alike can come from anywhere in
                the company.
              </li>
              <li>
                Sets expectations for what to expect from peers, managers,
                and leadership.
              </li>
            </ul>

  </div>
</div>
<div className='des_image'>
  <div className='des_imagein11'>
<div  className='icon2'  style={{ backgroundImage: `url(${WSP})`, backgroundSize: 'cover', background: 'cover' }} ></div>
{/* <Lottie animationData={ideationLottie} className='icon' loop={true} /> */}
</div>
</div>

</div>


<div className="second-section__right33" >
<h1 className='title'>Core Values
  </h1>
  <div className='para' >
  The company's core values revolve around integrity, reliability, and innovation. 
  They strive to stay ahead of industry trends and maintain a deep understanding of 
  the latest IT advancements to offer cutting-edge solutions to their clients.

  <br></br><br></br>
{/* We believe in delivering solutions with top notch quality and within expected time-lines. 
A key driver of SP Techno Services success has been the dedicated focus and emphasis on developing project 
management and quality enhancement systems. */}
  </div>
<div className="content" >
  {/* <h1 className='title'>Core Values:</h1> */}
<div className="content-box">
<div  className='values'>Transparency</div>
<div className='para1' >
Transparency is a core value that 
can help a company build trust with 
consumers and create a culture of innovation
  </div>
  <div className='line1'></div>
</div>
<div className="content-box">
<div  className='values'>Team Work</div>
<div className='para1' >
A company that values teamwork encourages employees 
to feel a sense of belonging and respect.
  </div>
  <div className='line1'></div>

</div>
<div className="content-box">
<div  className='values'>Responsibility</div>
<div className='para1' >
Transparency is a core value that can help a company build trust with consumers 
and create a culture of innovation
  </div>
  <div  className='line1' ></div>

</div>
  


  {/* <h1 className='title'>Core Values:</h1> */}
<div className="content-box">
<div  className='values'>Innovation</div>
<div className='para1' >
A company that follows innovative ideas and theories can develop customer trust and 
make employees interested in working there
  </div>
  <div className='line1'></div>
</div>
<div className="content-box">
<div  className='values'>Immediate <br></br>Assistance</div>
<div className='para1' >
Transparency is a core value that 
can help a company build trust with 
consumers and create a culture of innovation
  </div>
  <div className='line1'></div>

</div>
<div className="content-box">
<div  className='values'>Sustainability</div>
<div className='para1' >
A company that values sustainability is especially important when its outreach directly impacts its local environment and neighbors
  </div>
  <div className='line1'></div>

</div>
  

</div>


</div>
<div className="second-section__right3" style={{display:'flex',flexDirection:(isMobileScreen ? 'column':'row-reverse')}}>

<div className="content" >
  {isMobileScreen?  <h1 className='title'>Market 
  Position</h1> : <h1 className='title'>Market 
  <br></br>Position</h1> }
 
  <div className="line3" ></div>

  <div className='para'>
  As a trusted partner for many businesses in the region, 
  this New Jersey-based IT staffing company has grown steadily, 
  expanding its service offerings to address the evolving needs of the IT industry. 
  Its decade-long experience gives it a competitive edge in the staffing market, 
  allowing it to deliver both traditional and modern IT solutions with efficiency 
  and expertise.
  <br></br><br></br>

  </div>
</div>
<div className='des_image12'>
  <div className='des_imagein12'>
<img src={MP} alt='Qshikshak' className='icon12'  />
<img src={logoSP} alt='Qshikshak' style={ isMobileScreen? {width:100,marginLeft:0,marginBottom:100} :{width:130,marginLeft:0,marginBottom:100}}  />
{/* <Lottie animationData={workingLottie} className='icon12' loop={true} /> */}
</div>
<div  style={isMobileScreen? {width:190,height:150, backgroundColor:'#E2E7EB',marginTop:-90}: {width:'50%',height:'30%', backgroundColor:'#E2E7EB',marginTop:-90}}></div>
</div>

</div>
       
     
<section className="contact-section" >


      
<div className="contact-section__right2" style={{ backgroundImage: `url(${waves})`, backgroundSize: 'cover', background: 'cover' }}>

  <div className="content" style={{ borderRight : '3px solid white',marginRight:10,paddingRight:10}}>
    <h1 className='title'>Get In Touch
    <br></br></h1>

    <div className='button1' onClick={() => {
              navigate("/contact");
                scrollToTop();
            }}> 
    Contact Us
   
    </div>
  </div>
  <div className="content"  onClick={() => {
              navigate("/apply");
                scrollToTop();
            }} >
    <h1 className='title'>Looking For Job?
    <br></br></h1>
    <div className='button1'> 
    Apply Now
   
    </div>
  
  </div>
  
</div>


</section>
        
        
       
      </div>
    </div>
    </>
  );
}