import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import React from 'react'
import { Link } from 'react-router-dom'
import {  Menu, MenuItemProps } from 'semantic-ui-react'
import img from '../../images/SPlogo3.png'
import { TabNames } from '../Header/Header'
import './Footer.css'
import { useNavigate } from 'react-router-dom';

export const Footer = (props: {
    activeItem: TabNames,
    handleItemClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => void
}) => {
  const navigate = useNavigate();
    const scrollToTop = () => {
        window.scrollTo(0,0);
       }
       
    return (
      <footer className='footer'>
       
        <div className='footer__top'>
        <div className='footer__item1'>
        <h1 className="feature__heading">SP TECHNO</h1>
      <div className="feature__heading2">SOLUTIONS INC.</div>
                <img src={img} alt='sptechno' height={180} />
               
            </div>
    
           <div className='footer_in'>
            <div className='footer__item'>
                
                <div className='footer-menu-title'><h4>Services</h4></div>
                <div className='footer-flex'>
                
                <div  className='footer-menu-item4'>
                   {/* <LocationOnOutlinedIcon className='icon'></LocationOnOutlinedIcon> */}
                   <div >
                
                  <div  onClick={() => {
                navigate("/product");
                scrollToTop();
              }}
>Staffing &<br></br>
                  Support 
                  </div>
                </div>
                </div>
                 <div  className='footer-menu-item4'>
                   {/* <LocationOnOutlinedIcon className='icon'></LocationOnOutlinedIcon> */}
                   <div >
                
                  <div  onClick={() => {
                navigate("/product");
                scrollToTop();
              }}>Software  <br></br>
                  Development 
                  </div>
                </div>
                </div>
                
                <div  className='footer-menu-item4'>
                   {/* <LocationOnOutlinedIcon className='icon'></LocationOnOutlinedIcon> */}
                   <div >
                
                  <div onClick={() => {
                navigate("/product");
                scrollToTop();
              }} >IT Consulting  <br></br>
                Development
                  </div>
                </div>
                </div>
                <div  className='footer-menu-item4'>
                   {/* <LocationOnOutlinedIcon className='icon'></LocationOnOutlinedIcon> */}
                   <div >
                
                  <div onClick={() => {
                navigate("/product");
                scrollToTop();
              }}   >Mobile App  <br></br>
                  Support 
                  </div>
                </div>
                </div></div>
            </div>
            <div className='footer__item'>
                
                <div className='footer-menu-title'><h4>Quick Links</h4></div>
                <Menu text vertical className='footer-menu'>
                <Menu.Item
                    className='footer-menu-item'
                    name={TabNames.HOME}
                    active={props.activeItem === TabNames.HOME}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                        props.handleItemClick(event, data);
                        scrollToTop();
                    }}
                    as={Link}
                    to='/'
                />
                <Menu.Item
                    className='footer-menu-item'
                    name={TabNames.CONTACT}
                    active={props.activeItem === TabNames.CONTACT}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                        props.handleItemClick(event, data);
                        scrollToTop();
                    }}
                    as={Link}
                    to='/contact'
                />
                <Menu.Item
                    className='footer-menu-item'
                    name={TabNames.MOBILE}
                    active={props.activeItem === TabNames.MOBILE}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                        props.handleItemClick(event, data);
                        scrollToTop();
                    }}
                    as={Link}
                    to='/apply'
                />
                <Menu.Item
                    className='footer-menu-item'
                    name={TabNames.ABOUT_US}
                    active={props.activeItem === TabNames.ABOUT_US}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                        props.handleItemClick(event, data);
                        scrollToTop();
                    }}
                    as={Link}
                    to='/about-us'
                />
                </Menu>
            </div>
           
            <div className='footer__item'>
                <div className='footer-menu-title'><h4>Follow Us</h4></div>
                <div  className='footer-menu-item3' style={{ width:'100%',display:'flex',justifyContent:'center'}}>
                  <FacebookIcon  className='icon'></FacebookIcon> 
                
                </div>
                <div  className='footer-menu-item3'  style={{ width:'100%',display:'flex',justifyContent:'center'}}>
                  <LinkedInIcon  className='icon'></LinkedInIcon> 
                 
                </div>
            </div>
            </div>
            <div className='footer__itemend' >
            <div className='footer-menu-title'><h4>Address</h4></div>
                <div className='footer-flex'>
                
                <div  className='footer-menu-item2'>
                   {/* <LocationOnOutlinedIcon className='icon'></LocationOnOutlinedIcon> */}
                   <div >
                <div style={{color:'black',fontWeight:'400',}}>US Head Quarters:</div>
                  <div  > SP Techno Solutions Inc.   <br></br>
                    15 Corporate Pl South,
                    <br></br>
                  Suite #212, Piscataway,NJ-08854</div>
                  </div>
                </div>
                </div>
            </div>
        
        </div>
        <div className='footer__item2'>
      
                {/* <a href="https://www.quinterrasoft.com/" target="_blank">
                <div className='footer__pvt2'> All Rights Reserved. | Developed by Quinterra Software Solutions Pvt. Ltd.</div></a>
               */}
               <div  className='footer-menu-item2' style={{ borderRight : '1px solid black',marginRight:10,paddingRight:10}}>
                   <EmailOutlinedIcon className='icon1'></EmailOutlinedIcon>
                  
                     <div >
                
                  <div >Info@sptechnosolutions.com </div>
                  </div>
                </div>
            <div  className='footer-menu-item2'>
            <LocalPhoneOutlinedIcon  className='icon1'></LocalPhoneOutlinedIcon> 
                  
                     <div >
                
                  <div  >1937234862 </div>
                  </div>
                </div>
            </div>
      </footer>
    )
}
